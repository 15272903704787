<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <v-alert type="info">La contraseña debe poseer un mínimo de 8 carácteres</v-alert>
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-label>Ingrese una nueva contraseña</v-label>
                        <v-text-field type="password" v-model="creator.password" solo dense outlined prepend-inner-icon="vpn_key" label="Contraseña" :rules="[creation_rules.required, creation_rules.min]" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-label>Repita la nueva contraseña</v-label>
                        <v-text-field type="password" v-model="creator.pass_check" solo dense outlined prepend-inner-icon="vpn_key" label="Contraseña" :rules="[creation_rules.required, creation_rules.min, exact_pass]" />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="upload">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">Aplicar cambios</span>
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error_dialog" />
        <Message ref="dialog" @onClose="end_dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages, valid_required, valid_password } from '@/helpers'
import { mapState } from 'vuex'

export default {
    props: ['empleados'],
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        dialog: {
            visible: false,
            title: "Cambiar contraseña de acceso"
        },
        creator: {
            id: "",
            password: "",
            pass_check: ""
        },
        creation_rules: {
            required: value => valid_required(value),
            min: value => valid_password(value)
        }
    }),
    computed: {
        ...mapState('auth', ['prefix']),
        exact_pass() {
            return (this.creator.password == this.creator.pass_check) || "Las contraseñas deben coincidir";
        }
    },
    methods: {
        show(user) {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            this.creator.id = user.id;
        },
        close() {
            this.dialog.visible = false;
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        error(data) {
            let text = messages.texts["error_check_fields"];

            if(data) {
                text = messages.texts["error_desconocido"];
                text += "<br/>Código de error: " + (data.status || -1);
            }

            this.$refs.error_dialog.show(messages.titles["error_generico"], text, "error");
        },
        success() {
            this.$refs.dialog.show(messages.titles["success_generico"], messages.texts["success_usuario_pass"], "success")
        },
        end_dialog() {
            this.close();
            this.$emit('onSuccess');
        },
        upload() {

            if(!this.$refs.creator_form.validate()) {
                this.error();
                return;
            }

            axiosHelper.patch(this.prefix + 'usuarios/' + this.creator.id + '/', { password: this.creator.password })
                .then(() => this.success())
                .catch(error => this.error(error));
        }
    }
}
</script>