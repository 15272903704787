<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_usuarios_create' }">
                    <v-icon class="mr-2">person</v-icon>
                    <span class="subtitle-2">Crear acceso</span>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <DashCard title="Usuarios de la plataforma" title-icon="list" body-class="pa-0">
                    <UsuarioTable :items="gma_users" @change="$parent.$parent.reloadComponent()" />
                </DashCard>
            </v-col>
            <v-col cols="12">
                <DashCard title="Información" title-icon="info_outline" body-class="pa-0">
                    <v-card-text>
                        <span class="subtitle-1 text--primary">
                            Puede administrar accesos a la plataforma mediante la creación y edición de usuarios. <br/>
                            Cada usuario contiene un perfil de empleado, el cual es requerido por cada usuario que acceda a la plataforma y es utilizado para:
                            <ul>
                                <li>Cerrar e Invalidar Tareas de Compromisos Ambientales.</li>
                                <li>Cerrar e Invalidar Tareas de Compromisos de seguridad.</li>
                                <li>Cerrar e Invalidar Tareas de Tareas Críticas.</li>
                                <li>Identificación al insertar nuevos incidentes.</li>
                                <li>Identificación al insertar nuevas Tareas Críticas.</li>
                            </ul>
                        </span>
                    </v-card-text>
                </DashCard>
            </v-col>
        </v-row>
        <Message ref="dialog" />
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import UsuarioTable from '@/shared/components/UsuarioTable'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        DashCard,
        Message,
        UsuarioTable
    },
    data: () => ({
        index: []
    }),
    computed: {
        tabla_loading() {
            return !this.index;
        },
        gma_users() {
            return this.index.filter(x => x.groups.some(r => ['administradores', 'supers', 'empleados'].includes(r)));
        },
        cap_users() {
            return this.index.filter(x => x.groups.some(r => r.startsWith('cap')));
        }
    },
    mounted() {
        axiosHelper.get('usuarios/')
            .then(response => {
                this.index = response.data;
            })
        this.$emit('title', { text: "Accesos a la plataforma", icon: "edit" });
    }
}
</script>