<template>
<div>
    <div v-if="buscador" class="mx-2">
        <BuscadorTabla ref="buscador" class="px-4" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" />
    </div>
    <v-divider/>
    <v-data-table :headers="headers" :search="busqueda" :custom-filter="filtro" :items="items" :mobile-breakpoint="0">
        <template v-slot:item.username="{ item }">
            <v-chip dark color="indigo" icon="account_circle">
                <v-avatar left>
                    <v-icon>account_circle</v-icon>
                </v-avatar>
                {{ item.username }}
            </v-chip>
        </template>
        <template v-slot:item.perfil.first_name="{ item }">
            <div v-if="!capacitaciones">
                <span class="d-block subtitle-1 font-weight-bold">{{ item.perfil.first_name }} {{ item.perfil.last_name }}</span>
                <span class="d-block font-weight-medium">{{ item.perfil.cargo || "Cargo desconocido" }}</span>
            </div>
            <div v-else>
                <span class="d-block subtitle-2">{{ item.perfil.first_name }} {{ item.perfil.last_name }}</span>
                <span class="d-block caption">{{ item.perfil.rut }}</span>
            </div>
        </template>
        <template v-slot:item.perfil.empresa="{ item }">
            <template v-if="item.perfil.empresa">
                {{ item.perfil.empresa.nombre }}
            </template>
        </template>
        <template v-slot:item.perfil.gerencia="{ item }">
            <template v-if="item.perfil.gerencia">
                {{ item.perfil.gerencia.nombre }}
            </template>
        </template>
        <template v-slot:item.is_active="{ item }">
            <v-chip dark :color="item.is_active ? 'green' : 'red'">
                {{ item.is_active ? 'Activo' : 'Inactivo' }}
            </v-chip>
        </template>
        <template v-slot:item.groups="{ item }">
            <v-chip dark class="ma-1" label v-for="(group, i) in item.groups" v-bind:key="i">
                {{ get_formatted_group(group) }}
            </v-chip>
        </template>
        <template v-slot:item.date_joined="{ item }">
            {{ item.date_joined | moment("L HH:mm") }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn fab small text color="primary" depressed v-on="on">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item :to="{ name: 'dashboard_usuarios_edit', params: { id: item.id } }">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="show_editor(item)" v-if="item.is_active && (!capacitaciones ? !item.groups.includes('supers') : !isin(item.groups, ['supers', 'administradores', 'empleados']))">
                        <v-list-item-icon><v-icon>vpn_key</v-icon></v-list-item-icon>
                        <v-list-item-title>Cambiar contraseña</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ask_activate_user(item)" v-if="!item.is_active">
                        <v-list-item-icon><v-icon>done</v-icon></v-list-item-icon>
                        <v-list-item-title>Activar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ask_deactivate_user(item)" v-if="item.is_active && (!capacitaciones ? !item.groups.includes('supers') : !isin(item.groups, ['supers', 'administradores', 'empleados']))">
                        <v-list-item-icon><v-icon>close</v-icon></v-list-item-icon>
                        <v-list-item-title>Desactivar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ask_delete_user(item)" v-if="(!capacitaciones ? !item.groups.includes('supers') : !isin(item.groups, ['supers', 'administradores', 'empleados']))">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-data-table>
    <DialogEditCredenciales ref="pass_dialog" @onSuccess="$emit('change')" />
    <CommonDialog ref="activate_dialog" @onClose="activate_user" />
    <CommonDialog ref="deactivate_dialog" @onClose="deactivate_user" />
    <CommonDialog ref="delete_dialog" @onClose="delete_user" />
    <Message ref="dialog" />
</div>
</template>

<script>
import DialogEditCredenciales from '@/dash/dialogs/DialogEditCredenciales'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'
import { user_isin } from '@/helpers/utils'
import { mapState } from 'vuex'

export default {
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        capacitaciones: {
            type: Boolean,
            default: false   
        },
        buscador: {
            type: Boolean,
            default: false   
        }
    },
    components: {
        Message,
        BuscadorTabla,
        CommonDialog,
        DialogEditCredenciales
    },
    computed: {
        ...mapState('auth', ['prefix']),
        headers() {
            return this.capacitaciones ? this.cap_headers : this.main_headers;
        },
        tabla_items() {
            if(!this.$refs.buscador) return this.items;
            return this.items;
        },
        busqueda_settings() {
            return this.capacitaciones ? this.cap_busqueda_settings : this.main_busqueda_settings;
        }
    },
    data: () => ({
        busqueda: null,
        main_headers: [
            { text: "Nombre de usuario", value: "username", sortable: false, width: '5%', align: 'center' },
            { text: "Tipo de usuario", value: "groups", sortable: false, width: '20%', align: 'center' },
            { text: "Perfil", value: "perfil.first_name", sortable: false },
            { text: "Creado", value: "date_joined", sortable: false, width: '12.5%', align: 'center' },
            { text: "Estado", value: "is_active", sortable: false, width: '12.5%', align: 'center' },
            { text: "Acciones", value: "actions", width: '40px', sortable: false }
        ],
        cap_headers: [
            { text: "Nombre de usuario", value: "username", width: '5%', align: 'center' },
            { text: "Tipo de usuario", value: "groups", width: '20%', align: 'center' },
            { text: "Perfil", value: "perfil.first_name" },
            { text: "Empresa", value: "perfil.empresa", width: '12.5%' },
            { text: "Gerencia", value: "perfil.gerencia", width: '12.5%' },
            { text: "Creado", value: "date_joined", width: '12.5%', align: 'center' },
            { text: "Estado", value: "is_active", width: '12.5%', align: 'center' },
            { text: "Acciones", value: "actions", width: '40px', sortable: false }
        ],
        main_busqueda_settings: [
            { 
                value: null, 
                text: "Todo",
            },
            { 
                value: "username",
                text: "Nombre de usuario"
            },
            { 
                value: "groups",
                text: "Tipo de usuario"
            },
            { 
                value: "perfil.first_name", 
                text: "Nombre"
            }
        ],
        cap_busqueda_settings: [
            { 
                value: null, 
                text: "Todo",
            },
            { 
                value: "username",
                text: "Nombre de usuario"
            },
            { 
                value: "groups",
                text: "Tipo de usuario"
            },
            { 
                value: "perfil.empresa",
                text: "Empresa"
            },
            { 
                value: "perfil.gerencia", 
                text: "Gerencia"
            },
            { 
                value: "perfil.first_name", 
                text: "Nombre"
            }
        ]
    }),
    methods: {
        get_formatted_group(group) {
            if(group == 'administradores') return "GMA Admin";
            if(group == 'empleados') return "GMA Empleado";
            if(group == 'cap_evaluador') return "Evaluador";
            if(group == 'cap_participante') return "Participante";
            if(group == 'supers') return "Superusuario";
            return group;
        },
        isin(user_groups, groups) {
            return user_isin(user_groups, groups);
        },
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        ask_activate_user(item) {
            this.$refs.activate_dialog.showDialog(messages.titles['question_activate_user'], messages.texts['question_activate_user'], 'yes_no', item);
        },
        ask_deactivate_user(item) {
            this.$refs.deactivate_dialog.showDialog(messages.titles['question_deactivate_user'], messages.texts['question_deactivate_user'], 'yes_no', item);
        },
        ask_delete_user(item) {
            this.$refs.delete_dialog.showDialog(messages.titles['question_delete_user'], messages.texts['question_delete_user'], 'yes_no', item);
        },
        show_error() {
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_desconocido'], 'error');
        },
        show_editor(item) {
            this.$refs.pass_dialog.show(item);
        },
        activate_user(response, item) {
            if(response == "yes") {
                axiosHelper.patch(this.prefix + 'usuarios/' + item.id + '/', { is_active: true })
                    .then(() => this.$emit('change'))
                    .catch(() => this.show_error());
            }
        },
        deactivate_user(response, item) {
            if(response == "yes") {
                axiosHelper.patch(this.prefix + 'usuarios/' + item.id + '/', { is_active: false })
                    .then(() => this.$emit('change'))
                    .catch(() => this.show_error());
            }
        },
        delete_user(response, item) {
            if(response == "yes") {
                axiosHelper.del(this.prefix + 'usuarios/' + item.id + '/')
                    .then(() => this.$emit('change'))
                    .catch(() => this.show_error());
            }
        }
    }
}
</script>